<template>
  <div class="agenda-header">
    <Dropdown
      v-model="mode"
      :options="options"
      size="small"
      options-width="82"
      @value="handlerMode"
    />
    <Button
      :text="$translations.agenda.header.today"
      type="outline"
      color="gray"
      size="small"
      @clicked="handlerToday"
    />
  </div>
</template>

<script>
import { Button, Dropdown } from '@seliaco/red-panda'

export default {
  name: 'AgendaHeader',
  components: {
    Button,
    Dropdown
  },
  data () {
    const options = [
      {
        name: this.$translations.agenda.header.date,
        value: 'date'
      },
      {
        name: this.$translations.agenda.header.month,
        value: 'month'
      }
    ]

    return {
      options,
      mode: this.$translations.agenda.header[this.$route.query.mode]
    }
  },
  created () {
    if (localStorage.getItem('agenda-mode')) {
      const modeLocalStorage = localStorage.getItem('agenda-mode')

      this.$router
        .replace({
          query: {
            ...this.$route.query,
            mode: modeLocalStorage
          }
        })
        .catch(() => {})
    }
  },
  methods: {
    handlerMode (mode) {
      localStorage.setItem('agenda-mode', mode)

      this.$router.replace({
        name: 'Agenda',
        query: {
          ...this.$route.query,
          mode
        }
      })
    },
    handlerToday () {
      this.$router
        .replace({
          query: {
            ...this.$route.query,
            date: this.$moment().utc().format()
          }
        })
        .catch(() => {})
    }
  },
  watch: {
    $route (to) {
      if (to.query.mode) {
        this.mode = this.$translations.agenda.header[to.query.mode]
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.agenda-header
  display: grid
  grid-template-columns: 82px 56px
  gap: 8px
  align-items: center

  ::v-deep .dropdown
    &_input
      input
        background: var(--gray-5) !important
        border: 1px solid var(--gray-20) !important
      .icon-display
        color: var(--gray-80) !important
</style>

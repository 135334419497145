<template>
  <MainContainer
    :style="currentPageMaxWidth"
    :main-content-options="mainContentOptions"
    :drawer-options="drawerOptions"
    :route="$route"
    :router="$router"
    :user-name="userName"
    :language="$store.getters.language.lang"
    :app-version="$version"
    :header-action-custom="handlerHeaderActionsCustom"
    origin="specialist"
    @open-support="handleOpenSupport"
    @open-notification-adjustments="handleOpenNotificationsAdjustments"
    @sign-out="handleSignOut"
  >
    <!-- top tab bar -->
    <template v-slot:before-content>
      <SliderNav
        v-if="$route.name === 'Patients'"
        :options="sliderNavOptions.patients"
        :route="$route"
      />
    </template>

    <!-- header actions -->
    <template v-slot:header-action-custom>
      <AgendaHeader v-if="$route.name === 'Agenda'" />
    </template>

    <!-- content -->
    <template v-slot:content>
      <router-view ref="main-container-content" />

      <!-- notifications -->
      <NotificationHandler
        v-if="$firestore && $store.getters['auth/user']"
        :user-id="$store.getters['auth/user'].id"
        :firestore="$firestore"
        :store="$store"
        :lang="language.lang"
        @call-to-action="handleNotificationCallToAction"
        @emit-event="handleNotificationEvent"
      />

      <DiagnosticButton
        :language="$store.getters.language.lang"
        :app-version="$version"
        :user="$store.getters['auth/user']"
        v-if="!handlerHeaderActionsCustom"
      />
    </template>
  </MainContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import { Browser } from '@capacitor/browser'

import {
  MainContainer,
  SliderNav,
  NotificationHandler,
  DiagnosticButton
} from '@seliaco/red-panda'
import AgendaHeader from '@/views/agenda/components/AgendaHeader.vue'
import MainContentMixin from '@/mixins/main-content-mixin'
import { Acquisition, NotificationEvent } from '@/types/events'

export default {
  name: 'Main',
  components: {
    MainContainer,
    SliderNav,
    AgendaHeader,
    NotificationHandler,
    DiagnosticButton
  },
  mixins: [MainContentMixin],
  mounted () {
    this.getSliderNavOptions()
    this.loadEvents()
  },
  methods: {
    loadEvents () {
      this.$store.watch(
        () => this.unreadMessages,
        () => {
          if (this.unreadMessages) {
            enableChatEvents()
          }
        }
      )
      const enableChatEvents = async () => {
        const messageTabIndex = this.mainContentOptions.findIndex(
          (o) => o.route.name === 'Messages'
        )
        if (messageTabIndex !== -1) {
          this.mainContentOptions[messageTabIndex].badge = this.unreadMessages
        }
      }
    },
    handleSignOut () {
      this.$globalEvent.$emit('chat/disconnect')
      this.$auth.signOut()

      Acquisition.logout({
        user: this.$store.getters['auth/user']
      })

      this.$router.push({
        name: 'SignIn'
      })
    },
    handleOpenSupport () {
      let url = `https://wa.me/send?text=${this.$translations.support.message}&phone=${process.env.VUE_APP_SUPPORT_WHATSAPP}`
      if (this.$store.getters.platform !== 'web') {
        url = `whatsapp://send?text=${this.$translations.support.message}&phone=${process.env.VUE_APP_SUPPORT_WHATSAPP}`
      }
      window.open(url, '_blank')
    },
    handleOpenNotificationsAdjustments () {
      this.$router.push({
        name: 'NotificationSettings'
      })
    },
    handleNotificationCallToAction (url) {
      const isExternalUrl = url.includes('http')

      if (isExternalUrl) {
        return Browser.open({ url })
      }

      return this.$router.push(url)
    },
    handleNotificationEvent (event) {
      const body = {
        ...event.props,
        route: this.$route.name
      }

      switch (event.eventName) {
        case 'open':
          return NotificationEvent.InAppMessageViewed(body)
        case 'close':
          return NotificationEvent.InAppMessageDismissed(body)
        case 'cdt':
          return NotificationEvent.InAppMessageClicked(body)
      }
    },
    getSliderNavOptions () {
      this.sliderNavOptions.patients = [
        {
          text: this.$translations.patients.tabs.all,
          name: 'all',
          action: () => {
            this.$router.replace({
              query: {
                ...this.$route.query,
                tab: 'all'
              }
            })
          }
        },
        {
          text: this.$translations.patients.tabs.active,
          name: 'active',
          action: () => {
            this.$router.replace({
              query: {
                ...this.$route.query,
                tab: 'active'
              }
            })
          }
        },
        {
          text: this.$translations.patients.tabs.inactive,
          name: 'inactive',
          action: () => {
            this.$router.replace({
              query: {
                ...this.$route.query,
                tab: 'inactive'
              }
            })
          }
        }
        /*
        {
          text: this.$translations.patients.tabs.archived,
          name: 'archived',
          action: () => {
            this.$router.replace({
              query: {
                ...this.$route.query,
                tab: 'archived'
              }
            })
          }
        }
        */
      ]
    }
  },
  computed: {
    ...mapGetters({
      language: 'language',
      unreadMessages: 'chat/unreadMessages'
    }),
    userName () {
      if (this.$store.getters['auth/user']?.first_name) {
        return `${this.$store.getters['auth/user']?.first_name} ${this.$store.getters['auth/user']?.last_name}`
      }

      return ''
    },
    currentPageMaxWidth () {
      let maxWidth = {
        '--max-width': '1040px'
      }

      if (this.$route.name === 'Messages') {
        maxWidth = {
          '--max-width': 'unset'
        }
      }

      return maxWidth
    },
    handlerHeaderActionsCustom () {
      return this.$route.name === 'Agenda' || this.$route.name === 'Messages'
    }
  }
}
</script>

export default {
  data () {
    return {
      /**
       * Each route should have the following structure
       *
       * {
       *   icon: {
       *     on: String,
       *     off: String
       *   },
       *   route: {
       *     path: String,
       *     name: String // unique string,
       *     query: String,
       *     params: String
       *   },
       *   title: String,
       *   titleActionable: Boolean,
       *   showLogo: Boolean,
       *   navigationAction: Function,
       *   headerActions: [
       *     {
       *       icon: String,
       *       callback: Function
       *     }
       *   ]
       * }
       */
      mainContentOptions: [
        {
          icon: {
            on: 'icon-home-on',
            off: 'icon-home-off'
          },
          route: {
            path: '/',
            name: 'Home',
            query: null,
            params: null
          },
          title: this.$translations.navbar.home,
          showLogo: true
        },
        {
          icon: {
            on: 'icon-specialists-on',
            off: 'icon-specialists-off'
          },
          route: {
            path: '/patients?tab=all',
            name: 'Patients',
            query: null,
            params: null
          },
          title: this.$translations.navbar.patients,
          headerAction: [
            {
              icon: 'icon-search-off',
              callback: () => {
                this.$router.push({
                  name: 'PatientsSearch'
                })
              }
            }
          ]
        },
        {
          icon: {
            on: 'icon-timer-calendar-on',
            off: 'icon-timer-calendar-off'
          },
          route: {
            path: '/agenda',
            name: 'Agenda',
            query: {
              mode: 'date'
            },
            params: null
          },
          title: this.$translations.navbar.agenda
        },
        {
          icon: {
            on: 'icon-message-circle-on',
            off: 'icon-message-circle-off'
          },
          route: {
            path: '/messages',
            name: 'Messages',
            query: null,
            params: null
          },
          title: this.$translations.navbar.messages,
          badge: null
        },
        {
          icon: {
            on: 'icon-my-office-on',
            off: 'icon-my-office-off'
          },
          route: {
            path: '/my-office',
            name: 'Office',
            query: null,
            params: null
          },
          title: this.$translations.navbar.office
        }
      ],
      /**
       * Each drawer option should have the following structure
       *
       *  [
       *    {
       *       icon: String,
       *       text: String,
       *       action: Function,
       *       hideDivider: Boolean
       *    }
       *  ]
       */
      drawerOptions: [
        {
          icon: 'icon-person-off',
          text: this.$translations['profile-modal']['view-profile'],
          action: () => {
            this.$router.push({ name: 'Profile' })
          },
          hideDivider: true
        },
        {
          icon: 'icon-clock-off',
          text: this.$translations['profile-modal'].schedule,
          action: () => {
            this.$router.push({
              name: 'Profile',
              query: {
                section: 'schedule'
              }
            })
          },
          hideDivider: true
        },
        {
          icon: 'icon-book-open',
          text: this.$translations.directory,
          action: () => {
            this.$router.push({
              name: 'SpecialistsDirectory'
            })
          },
          hideDivider: true
        },
        {
          icon: 'icon-notes-off',
          text: this.$translations['profile-modal'].documents,
          action: () => {
            this.$router.push({
              name: 'Documents'
            })
          },
          hideDivider: false
        }
      ],
      sliderNavOptions: { patients: [] }
    }
  },
  watch: {
    $route (route) {
      this.mainContentOptions = this.mainContentOptions.map((option) => {
        if (option.route.name === route.name) {
          option.route.query = {
            ...option.route.query,
            ...route.query
          }
          option.route.params = route.params
        } else {
          option.route.query = null
          option.route.params = null
        }
        return option
      })
      if (route.name === 'Patients') {
        if (!this.$route.query?.tab) {
          this.$router.replace({
            query: {
              tab: 'all'
            }
          })
        }
      }
    }
  }
}
